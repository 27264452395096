import {
  updateSettings,
  sendEmail,
} from "@/services/api/app/settings";
import Store from "@/store/app/index";

const defaultState = () => ({
  settings: null,
  providerType: 2,
})

export default {
  namespaced: true,

  actions: {
    updateSettings({commit, state}, settingsForm) {
      return updateSettings(settingsForm, state.providerType)
        .then((result) => {
          commit('setSettings', result)
          return result
        })
    },

    sendEmail({commit, dispatch}) {
      return sendEmail(2);
    },
  },

  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
  },

  getters: {},

  state: defaultState()
}
