import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import administrators from './admin/administrator'

// Office
import office from "@/store/app/office";

// Google
import google from '@/store/app/google'

// Dropbox
import dropbox from '@/store/app/dropbox'

// Box
import box from '@/store/app/box'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)


const Store = new Vuex.Store({

  modules: {
    auth,
    administrators,
    office,
    google,
    dropbox,
    box,
  },
  plugins: [createPersistedState({
    key: 'idrive',
    paths: ['auth.accessToken'],
  })],
  mutations: {
    setProviderTenant(state, providerTenant) {
      state.providerTenant = providerTenant

      if (providerTenant === 'GOOGLE') {
        state.providerUrl = 'google'
      } else if (providerTenant === 'DROPBOX') {
        state.providerUrl = 'dropbox'
      } else if (providerTenant === 'BOX') {
        state.providerUrl = 'box'
      } else {
        state.providerUrl = 'office'
      }
    },

    setCollection(state, collection) {
      state.collection = collection;
    }
  },
  state: {
    providerTenant: null,
    providerUrl: null,
    collection: null,
  }
})

export default Store;

export {Store} ;

