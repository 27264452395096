import Store from "@/store/app";
import {generateMicrosoftAuthLink} from "@/services/microsoft";

let ENVIRONMENT = 'staging';

if (process.env.VUE_APP_ENVIRONMENT) {
  ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;
}

const getRoutes = store => {

  const checkAuth = (to, from, next) => {
    const accessToken = to.query?.accessToken;
    if (accessToken) {
      Store.commit('auth/setAccessToken', accessToken);
    }

    const data = {
      startDate: "2023-10-03T22:00:00.000Z",
      endDate: "2023-10-05T21:59:59.999Z"
    }

    Store.dispatch("auth/getSelf")
      .then(() => Promise.all([
        Store.dispatch("office/stats/getStats", data),
        Store.dispatch("google/stats/getStats", data),
        Store.dispatch("dropbox/stats/getStats", data),
        Store.dispatch("box/stats/getStats", data),
      ]))
      .then(() => next())
      .catch(() => {
        next({
          name: "SignInPage"
        });
      });
  }

  const checkNoAuth = (to, from, next) => {
    if (Store.state.auth.accessToken) {
      next({
        name: "HomeLayout"
      });
    } else {
      next();
    }
  };

  const checkNoTenant = (to, from, next) => {
    if (Store.state.auth.self.providerTenants && Store.state.auth.self.providerTenants.length) {
      if (Store.getters['auth/isMicrosoftProviderEnabled']) {
        next({
          name: "MicrosoftHomePage",
          replace: true
        });
      } else if (Store.getters['auth/isGoogleProviderEnabled']) {
        next({
          name: "GoogleHomePage"
        });
      } else if (Store.getters['auth/isDropboxProviderEnabled']) {
        next({
          name: "DropboxHomePage"
        });
      } else if (Store.getters['auth/isBoxProviderEnabled']) {
        next({
          name: "BoxHomePage"
        });
      } else {
        next();
      }

    } else {
      next();
    }
  };

  const singleSignOn = (routeName) => (to, from, next) => {
    Store.commit('auth/setAccessToken', to.params.token)
    next({
      name: routeName,
    });
  }

  const checkIsProviderConnected = (providerType) => (to, from, next) => {
    if (Store.getters['auth/isProviderConnected'](providerType)) {
      if (providerType === 1) {
        next({
          name: 'MicrosoftHomePage'
        })
      } else if (providerType === 2) {
        next({
          name: 'GoogleHomePage'
        })
      } else if (providerType === 3) {
        next({
          name: 'DropboxHomePage'
        })
      } else if (providerType === 4) {
        next({
          name: 'BoxHomePage'
        })
      } else {
        next()
      }

    } else {
      next()
    }
  }

  const checkActiveUsers = (provider, stats) => {
    return stats?.reduce((acc, stat) => {
      return Store.state[provider]?.stats.stats[stat]?.activeUsers > 0;
    }, false);
  }

  const checkProviderTenant = (provider, routeName) => (to, from, next) => {
    if (Store.state.auth.self) {
      if (Store.getters[`auth/is${provider}ProviderEnabled`]) {
        if (Store.state.auth.self?.providerTenants?.find(item => item?.providerTenantName === provider)?.disableTime === null) {
          next()
        } else {
          next({
            name: 'AccountDisabled'
          })
        }
      } else {
        next({
          name: routeName
        })
      }
    } else {
      next({
        name: 'HomeLayout'
      })
    }
  }

  const checkHasProviderAccess = (accessLevels, tenantType) => (to, from, next) => {
    let providerLevelType = 0
    providerLevelType = Store?.state?.auth?.self?.providerTenants?.find(providerTenant => providerTenant?.providerTenantType === tenantType)?.levelType

    if (accessLevels.includes(providerLevelType)) {
      next({})
    } else {
      next({
        name: "HomeLayout"
      });
    }
  }

  const checkMicrosoftTenant = (to, from, next) => {
    if (Store.state.auth.self) {
      if (to.name === 'MicrosoftWelcomePage' && to.query?.accessToken) {
        next();
      } else if (Store.getters['auth/isMicrosoftProviderEnabled']) {
        if (Store.state.auth.self?.providerTenants?.find(item => item?.providerTenantName === 'Microsoft')?.disableTime === null) {
          next()
        } else {
          next({
            name: 'AccountDisabled'
          })
        }
      } else {
        next({
          name: 'ConnectMicrosoftLayout'
        })
      }
    } else {
      next({
        name: 'HomeLayout'
      })
    }
  }

  const checkActiveMicrosoftUsers = (to, from, next) => {
    // if (checkActiveUsers('office', ['driveStats', 'exchangeStats', 'siteStats', 'teamStats']) || to.name === 'MicrosoftWelcomePage') {
    next();
    // } else {
    //   next({
    //     name: 'MicrosoftWelcomePage'
    //   })
    // }
  }

  const checkActiveGoogleUsers = (to, from, next) => {
    // if (checkActiveUsers('google', ['calendarStats', 'contactStats', 'googleSharedDriveStats', 'driveStats', 'googleMailStats']) || to.name === 'GoogleWelcomePage') {
    next();
    // } else {
    //   next({
    //     name: 'GoogleWelcomePage'
    //   })
    // }
  }

  const checkActiveDropboxUsers = (to, from, next) => {
    // if (checkActiveUsers('google', ['googleSharedFoldersStats', 'driveStats']) || to.name === 'DropboxWelcomePage') {
    next();
    // } else {
    //   next({
    //     name: 'DropboxWelcomePage'
    //   })
    // }
  }

  const checkActiveBoxUsers = (to, from, next) => {
    // if (checkActiveUsers('google', ['driveStats']) || to.name === 'BoxWelcomePage') {
    next();
    // } else {
    //   next({
    //     name: 'BoxWelcomePage'
    //   })
    // }
  }

  const logoutAndRedirect = (from, to, next) => {
    Store.dispatch('auth/signOut', false)
      .then(() => {
        if (from.query.redirect_url) {
          window.location.href = from.query.redirect_url
        } else {
          if (Store.state.auth?.self?.platform === "360") {
            window.location.href = 'https://webapp.idrive360.com/api/v1/logout'
          } else {
            window.location.href = 'https://www.idrive.com/idriveent/login/logout'
          }
        }
        // next(true)

      })
  }


  const routes = [
    {
      path: '/auth',
      component: () => import('../../layout/AuthLayout'),
      beforeEnter: checkNoAuth,
      children: [
        {
          path: 'sign-up',
          name: 'SignUpPage',
          component: () => import('../../pages/app/auth/SignUpPage')
        },
        {
          path: 'sign-in',
          name: 'SignInPage',
          component: () => import('../../pages/app/auth/SignInPage')
        },
        {
          path: 'forgot-password',
          name: "ForgotPasswordPage",
          component: () => import('../../pages/app/auth/ForgotPasswordPage')
        },
      ]
    },
    {
      path: '/',
      component: () => import('../../layout/MainLayout'),
      beforeEnter: checkAuth,
      children: [
        // MICROSOFT365 START
        {
          path: '/microsoft-365/register-error',
          name: 'MicrosoftRegisterErrorPage',
          component: () => import('../../pages/app/auth/MicrosoftRegisterErrorPage')
        },
        {
          path: 'microsoft-365',
          component: () => import('../../layout/app/office/AppLayout'),
          beforeEnter: checkMicrosoftTenant,
          children: [
            {
              path: '',
              component: () => import('../../layout/PageLayout'),
              children: [
                {
                  path: 'welcome',
                  name: 'MicrosoftWelcomePage',
                  component: () => import('@/layout/app/office/WelcomePage'),
                  beforeEnter: checkActiveMicrosoftUsers,
                },
                {
                  path: 'home',
                  name: 'MicrosoftHomePage',
                  meta: {activeMenu: 'overview'},
                  component: () => import('@/pages/app/common/overview/HomePage'),
                  beforeEnter: checkActiveMicrosoftUsers,
                },
                // {
                //   path: 'administrators',
                //   name: 'AdministratorsMicrosoftPage',
                //   meta: {activeMenu: 'administrators'},
                //   component: () => import('../pages/office/admin/AdministratorsPage'),
                // },
                {
                  path: 'reports',
                  name: 'OfficeReportsPage',
                  redirect: {name: 'OfficeSystemEventsPage'},
                  component: () => import('@/pages/app/common/activity-logs/ReportsPage'),
                  beforeEnter: checkActiveMicrosoftUsers,
                  children: [
                    {
                      path: 'system-events',
                      name: 'OfficeSystemEventsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/SystemEventsPage'),
                    },
                    {
                      path: 'user-actions',
                      name: 'OfficeUserActionsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/UserActionsPage'),
                    },
                    {
                      path: 'event-logs',
                      name: 'OfficeAllEventLogsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/AllEventLogsPage'),
                    },
                  ]
                },
                {
                  path: 'settings',
                  name: 'OfficeSettingsPage',
                  meta: {activeMenu: 'settings'},
                  component: () => import('../../pages/app/common/SettingsPage'),
                  beforeEnter: checkActiveMicrosoftUsers,
                },
              ],
            },
            {
              path: 'seat-management',
              component: () => import('../../layout/PageLayout'),
              beforeEnter: checkActiveMicrosoftUsers,
              children: [
                {
                  path: 'users',
                  name: 'UsersPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 1),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'users'},
                },
                {
                  path: 'user-personal',
                  name: 'UserPersonalPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([2], 1),
                  component: () => import('../../pages/app/office/seat-management/UserPersonalPage'),
                },
                {
                  path: 'sites',
                  name: 'SitesPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 1),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'sites'},
                },
                {
                  path: 'teams',
                  name: 'TeamsPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 1),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'teams'},
                },
                {
                  path: 'mailboxes',
                  name: 'MailboxesPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 1),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'mailboxes'},
                },
              ],
            },
            {
              path: 'recovery',
              component: () => import('../../layout/PageLayout'),
              beforeEnter: checkActiveMicrosoftUsers,
              children: [
                {
                  path: 'onedrive',
                  name: 'OneDrivePage',
                  redirect: {name: 'OneDriveUsersPage'},

                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'oneDrive'},
                  children: [
                    {
                      path: 'users',
                      name: 'OneDriveUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryOneDriveUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'OneDriveSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'OneDriveExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'OneDriveRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'OneDriveBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'outlook',
                  name: 'ExchangePage',

                  redirect: {name: 'ExchangeUsersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'exchange'},

                  children: [
                    {
                      path: 'users',
                      name: 'ExchangeUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:dataSet(mail|contact|calendar|task)/:folderId?',
                      name: 'RecoveryExchangeUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      // component: () => import('../layout/office/recovery/exchange/UserDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'ExchangeSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'ExchangeExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'ExchangeRestorePage',
                      meta: {activeMenu: 'recovery'},
                      beforeEnter: checkHasProviderAccess([1, 3], 1),
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'ExchangeBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'sharepoint',
                  name: 'SharePointPage',
                  redirect: {name: 'SharePointSitesPage'},
                  props: {collection: 'sharePoint'},
                  beforeEnter: checkHasProviderAccess([1, 3], 1),
                  component: () => import('../../layout/PageLayout'),
                  children: [
                    {
                      path: 'sites',
                      name: 'SharePointSitesPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/office/recovery/share-point/SitesPage'),
                    },
                    {
                      path: 'sites/:siteId/:dataSet(drive|list)/:folderId?',
                      name: 'RecoverySharePointSiteDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/layout/app/office/recovery/share-point/SiteDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'SharePointSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'SharePointExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'SharePointRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'SharePointBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'teams',
                  name: 'RTeamsPage',
                  redirect: {name: 'TeamsTeamsPage'},
                  beforeEnter: checkHasProviderAccess([1, 3], 1),
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'team'},
                  children: [
                    {
                      path: 'teams',
                      name: 'TeamsTeamsPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../../pages/app/office/recovery/team/TeamsPage'),
                    },
                    {
                      path: 'teams/:teamId/:dataSet(drive|conversation)/:channelId?/:folderId?',
                      name: 'RecoveryTeamDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../../layout/app/office/recovery/team/TeamDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'TeamSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'TeamExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'TeamRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'TeamBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
              ]
            },
          ]
        },
        // MICROSOFT365 END
        {
          path: 'connect-google',
          name: 'ConnectGoogleLayout',
          component: () => import('../../layout/app/google/ConnectAccountLayout'),
          beforeEnter: checkNoTenant && !checkProviderTenant('Google', 'ConnectGoogleLayout') || checkIsProviderConnected(2)
        },
        {
          path: 'connect-microsoft',
          name: 'ConnectMicrosoftLayout',
          component: () => import('../../layout/app/office/ConnectAccountLayout'),
          beforeEnter: checkNoTenant && !checkMicrosoftTenant || checkIsProviderConnected(1)
        },
        {
          path: 'connect-dropbox',
          name: 'ConnectDropboxLayout',
          component: () => import('../../layout/app/dropbox/ConnectAccountLayout'),
          beforeEnter: checkNoTenant && !checkProviderTenant('Dropbox', 'ConnectDropboxLayout') || checkIsProviderConnected(3)
        },
        {
          path: '/dropbox/register-error',
          name: 'DropboxWorkspaceRegisterErrorPage',
          component: () => import('../../pages/app/auth/DropboxWorkspaceRegisterErrorPage')
        },
        {
          path: 'connect-box',
          name: 'ConnectBoxLayout',
          component: () => import('../../layout/app/box/ConnectAccountLayout'),
          beforeEnter: checkNoTenant && !checkProviderTenant('Box', 'ConnectBoxLayout') || checkIsProviderConnected(4)
        },
        {
          path: '/box/register-error',
          name: 'BoxWorkspaceRegisterErrorPage',
          component: () => import('../../pages/app/auth/BoxWorkspaceRegisterErrorPage')
        },
        {
          path: 'add-googleworkspace',
          name: 'GoogleWorkspaceLayout',
          component: () => import('../../layout/app/google/GoogleWorkspaceLayout'),
          beforeEnter: checkNoTenant && !checkProviderTenant('Google', 'ConnectGoogleLayout') || checkIsProviderConnected(2)
        },
        {
          path: '/google/register-error',
          name: 'GoogleWorkspaceRegisterErrorPage',
          component: () => import('../../pages/app/auth/GoogleWorkspaceRegisterErrorPage')
        },
        // GOOGLE START
        {
          path: 'google',
          component: () => import('../../layout/app/google/AppLayout'),
          beforeEnter: checkProviderTenant('Google', 'ConnectGoogleLayout'),
          children: [
            {
              path: '',
              component: () => import('../../layout/PageLayout'),
              children: [
                {
                  path: 'welcome',
                  name: 'GoogleWelcomePage',
                  component: () => import('../../layout/app/google/WelcomePage'),
                  beforeEnter: checkActiveGoogleUsers,
                },
                {
                  path: 'home',
                  name: 'GoogleHomePage',
                  meta: {activeMenu: 'overview'},
                  component: () => import('@/pages/app/common/overview/HomePage'),
                  beforeEnter: checkActiveGoogleUsers,
                },
                // {
                //   path: 'administrators',
                //   name: 'AdministratorsGooglePage',
                //   meta: {activeMenu: 'administrators'},
                //   component: () => import('../pages/office/admin/AdministratorsPage'),
                // },
                {
                  path: 'reports',
                  name: 'GoogleReportsPage',
                  redirect: {name: 'GoogleSystemEventsPage'},
                  component: () => import('@/pages/app/common/activity-logs/ReportsPage'),
                  beforeEnter: checkActiveGoogleUsers,
                  children: [
                    {
                      path: 'system-events',
                      name: 'GoogleSystemEventsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/SystemEventsPage'),
                    },
                    {
                      path: 'user-actions',
                      name: 'GoogleUserActionsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/UserActionsPage'),
                    },
                    {
                      path: 'event-logs',
                      name: 'GoogleAllEventLogsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/AllEventLogsPage'),
                    },
                  ]
                },
                {
                  path: 'settings',
                  name: 'GoogleSettingsPage',
                  meta: {activeMenu: 'settings'},
                  component: () => import('../../pages/app/common/SettingsPage'),
                  beforeEnter: checkActiveGoogleUsers,
                },
              ],
            },
            {
              component: () => import('../../layout/PageLayout'),
              path: 'seat-management',
              beforeEnter: checkActiveGoogleUsers,
              children: [
                {
                  path: 'users',
                  name: 'GUsersPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 2),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'users'},
                },
                {
                  path: 'user-personal',
                  name: 'GUserPersonalPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([2], 2),
                  component: () => import('../../pages/app/google/seat-management/UserPersonalPage'),
                },
                {
                  path: 'shared-drives',
                  name: 'SMSharedDrivePage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 2),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'sharedDrives'},
                },
              ],
            },
            {
              path: 'recovery',
              component: () => import('../../layout/PageLayout'),
              beforeEnter: checkActiveGoogleUsers,
              children: [
                {
                  path: 'drive',
                  name: 'DrivePage',
                  redirect: {name: 'DriveUsersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'drive'},

                  children: [
                    {
                      path: 'users',
                      name: 'DriveUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'search',
                      name: 'DriveSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/SearchPage'),
                    },

                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryDriveUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      props: true,
                    },

                    {
                      path: 'exports',
                      name: 'DriveExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'DriveRestoresPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'DriveBackupsPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'gmail',
                  name: 'GmailPage',
                  redirect: {name: 'GmailUsersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'gmail'},
                  children: [
                    {
                      path: 'users',
                      name: 'GmailUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:dataSet(folders|mails)/:folderId?',
                      name: 'RecoveryGmailUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'GmailSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'GmailExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'GmailRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'GmailBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'contact',
                  name: 'ContactPage',
                  redirect: {name: 'ContactUsersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'contact'},
                  children: [
                    {
                      path: 'users',
                      name: 'ContactUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryContactUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'ContactSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'ContactExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'ContactRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'ContactBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'calendar',
                  name: 'CalendarPage',
                  redirect: {name: 'CalendarUsersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'calendar'},
                  children: [
                    {
                      path: 'users',
                      name: 'CalendarUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryCalendarUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'CalendarSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'CalendarExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'CalendarRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'CalendarBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'shared-drives',
                  name: 'RSharedDrivesPage',
                  redirect: {name: 'SharedDrivesPage'},
                  beforeEnter: checkHasProviderAccess([1, 3], 2),
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'sharedDrive'},

                  children: [
                    {
                      path: 'shared-drives',
                      name: 'SharedDrivesPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/google/recovery/shared-drive/SharedDrivesPage'),
                    },
                    {
                      path: 'shared-drives/:sharedDriveId/:folderId?',
                      name: 'RecoverySharedDriveDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/layout/app/google/recovery/shared-drive/SharedDriveDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'SharedDriveSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'SharedDriveExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'SharedDriveRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'SharedDriveBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
              ]
            },
          ]
        },
        // GOOGLE END

        // DROPBOX START
        {
          path: 'dropbox',
          component: () => import('../../layout/app/dropbox/AppLayout'),
          beforeEnter: checkProviderTenant('Dropbox', 'ConnectDropboxLayout'),
          children: [
            {
              path: '',
              component: () => import('../../layout/PageLayout'),
              children: [
                {
                  path: 'welcome',
                  name: 'DropboxWelcomePage',
                  component: () => import('../../layout/app/dropbox/WelcomePage'),
                  beforeEnter: checkActiveDropboxUsers,
                },
                {
                  path: 'home',
                  name: 'DropboxHomePage',
                  meta: {activeMenu: 'overview'},
                  component: () => import('@/pages/app/common/overview/HomePage'),
                  beforeEnter: checkActiveDropboxUsers,
                },
                // {
                //   path: 'administrators',
                //   name: 'AdministratorsGooglePage',
                //   meta: {activeMenu: 'administrators'},
                //   component: () => import('../pages/office/admin/AdministratorsPage'),
                // },
                {
                  path: 'reports',
                  name: 'DropboxReportsPage',
                  redirect: {name: 'DropboxSystemEventsPage'},
                  component: () => import('@/pages/app/common/activity-logs/ReportsPage'),
                  beforeEnter: checkActiveDropboxUsers,
                  children: [
                    {
                      path: 'system-events',
                      name: 'DropboxSystemEventsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/SystemEventsPage'),
                    },
                    {
                      path: 'user-actions',
                      name: 'DropboxUserActionsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/UserActionsPage'),
                    },
                    {
                      path: 'event-logs',
                      name: 'DropboxAllEventLogsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/AllEventLogsPage'),
                    },
                  ]
                },
                {
                  path: 'settings',
                  name: 'DropboxSettingsPage',
                  meta: {activeMenu: 'settings'},
                  component: () => import('../../pages/app/common/SettingsPage'),
                  beforeEnter: checkActiveDropboxUsers,
                },
              ],
            },
            {
              path: 'seat-management',
              component: () => import('../../layout/PageLayout'),
              children: [
                {
                  path: 'users',
                  name: 'DropboxUsersPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 3),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'users'},
                },
                {
                  path: 'shared-folders',
                  name: 'SMSharedFolderPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 2, 3], 3),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'sharedFolders'},
                },
                {
                  path: 'user-personal',
                  name: 'DropboxUserPersonalPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([2], 3),
                  component: () => import('../../pages/app/dropbox/seat-management/UserPersonalPage'),
                },
              ],
            },
            {
              path: 'recovery',
              component: () => import('../../layout/PageLayout'),
              children: [
                {
                  path: 'drive',
                  name: 'DropboxDrivePage',
                  redirect: {name: 'DropboxDriveUsersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'dropboxDrive'},

                  children: [
                    {
                      path: 'users',
                      name: 'DropboxDriveUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'search',
                      name: 'DropboxDriveSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryDropboxDriveUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      props: true,
                    },

                    {
                      path: 'exports',
                      name: 'DropboxDriveExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'DropboxDriveRestoresPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'DropboxDriveBackupsPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'shared-folders',
                  name: 'RSharedFoldersPage',
                  redirect: {name: 'SharedFoldersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'sharedFolder'},

                  children: [
                    {
                      path: 'shared-folders',
                      name: 'SharedFoldersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/dropbox/recovery/shared-folder/SharedFoldersPage'),
                    },
                    {
                      path: 'shared-folders/:sharedFolderId/:folderId?',
                      name: 'RecoverySharedFolderDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/layout/app/dropbox/recovery/shared-folder/SharedFolderDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'SharedFolderSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'export',
                      name: 'SharedFolderExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'SharedFolderRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'SharedFolderBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
              ]
            },
          ]
        },
        // DROPBOX END

        // BOX START
        {
          path: 'box',
          component: () => import('../../layout/app/box/AppLayout'),
          beforeEnter: checkProviderTenant('Box', 'ConnectBoxLayout'),
          children: [
            {
              path: '',
              component: () => import('../../layout/PageLayout'),
              children: [
                {
                  path: 'welcome',
                  name: 'BoxWelcomePage',
                  component: () => import('../../layout/app/box/WelcomePage'),
                  beforeEnter: checkActiveBoxUsers,
                },
                {
                  path: 'home',
                  name: 'BoxHomePage',
                  meta: {activeMenu: 'overview'},
                  component: () => import('@/pages/app/common/overview/HomePage'),
                  beforeEnter: checkActiveBoxUsers,
                },
                {
                  path: 'reports',
                  name: 'BoxReportsPage',
                  redirect: {name: 'BoxSystemEventsPage'},
                  component: () => import('@/pages/app/common/activity-logs/ReportsPage'),
                  beforeEnter: checkActiveBoxUsers,
                  children: [
                    {
                      path: 'system-events',
                      name: 'BoxSystemEventsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/SystemEventsPage'),
                    },
                    {
                      path: 'user-actions',
                      name: 'BoxUserActionsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/UserActionsPage'),
                    },
                    {
                      path: 'event-logs',
                      name: 'BoxAllEventLogsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('@/pages/app/common/activity-logs/reporting/AllEventLogsPage'),
                    },
                  ]
                },
                {
                  path: 'settings',
                  name: 'BoxSettingsPage',
                  meta: {activeMenu: 'settings'},
                  component: () => import('../../pages/app/common/SettingsPage'),
                  beforeEnter: checkActiveBoxUsers,
                },
              ],
            },
            {
              path: 'seat-management',
              component: () => import('../../layout/PageLayout'),
              beforeEnter: checkActiveBoxUsers,
              children: [
                {
                  path: 'users',
                  name: 'BoxUsersPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([1, 3], 4),
                  component: () => import('@/pages/app/common/seat-management/SeatsPage'),
                  props: {collection: 'users'},
                },
                {
                  path: 'user-personal',
                  name: 'BoxUserPersonalPage',
                  meta: {activeMenu: 'seat-management'},
                  beforeEnter: checkHasProviderAccess([2], 4),
                  component: () => import('../../pages/app/box/seat-management/UserPersonalPage'),
                },
              ],
            },
            {
              path: 'recovery',
              component: () => import('../../layout/PageLayout'),
              beforeEnter: checkActiveBoxUsers,
              children: [
                {
                  path: 'drive',
                  name: 'BoxDrivePage',
                  redirect: {name: 'BoxDriveUsersPage'},
                  component: () => import('../../layout/PageLayout'),
                  props: {collection: 'boxDrive'},
                  children: [
                    {
                      path: 'users',
                      name: 'BoxDriveUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UsersPage'),
                    },
                    {
                      path: 'search',
                      name: 'BoxDriveSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/SearchPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryBoxDriveUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/UserDetailsPage'),
                      props: true,
                    },

                    {
                      path: 'exports',
                      name: 'BoxDriveExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'BoxDriveRestoresPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'BoxDriveBackupsPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('@/pages/app/common/BackupPage'),
                    },
                  ]
                },
              ]
            },
          ]
        },
        // BOX END

        {
          path: '',
          name: 'HomeLayout',
          component: () => import('@/layout/app/HomeLayout'),
        }
      ]
    },
    {
      path: '/account-disabled',
      name: 'AccountDisabled',
      component: () => import('@/pages/app/auth/AccountDisabled'),
    },
    {
      path: '/google-workspace-error-page',
      name: 'PublicGoogleErrorPage',
      component: () => import('@/pages/app/google/PublicGoogleErrorPage'),
    },
    {
      path: '/sso/microsoft/:token',
      beforeEnter: singleSignOn('MicrosoftHomePage'),
    },
    {
      path: '/sso/google/:token',
      beforeEnter: singleSignOn('GoogleHomePage'),
    },
    {
      path: '/sso/dropbox/:token',
      beforeEnter: singleSignOn('DropboxHomePage'),
    },
    {
      path: '/sso/box/:token',
      beforeEnter: singleSignOn('BoxHomePage'),
    },
    {
      path: '/sso/common/:token',
      beforeEnter: singleSignOn('HomeLayout'),
    },
    {
      path: '/logout',
      name: 'LogoutPage',
      component: () => import('../../pages/LogoutPage'),
    },
    {
      path: '/sign-in-test',
      name: 'SignInTestPage',
      component: () => import('../../pages/app/auth/SignInTestPage')
    },
    {
      path: '/logout/redirect',
      name: 'LogoutAndRedirectPage',
      component: () => import('@/pages/LogoutPage'),
      beforeEnter: logoutAndRedirect,
    },
    {
      path: '/admin-consent',
      name: 'AdminConsent',
      component: () => import('@/pages/app/office/AdminConsentPage')
    },
    {
      path: '/offline',
      name: 'OfflinePage',
      component: () => import('@/pages/OfflinePage'),
      beforeEnter: checkAuth,
    },
    // Always leave this as last one
    {
      path: '*',
      name: 'ErrorPage',
      component: () => import('@/pages/ErrorPage'),
      beforeEnter: checkAuth,
    }
  ]

  return routes;
}

export {getRoutes};
