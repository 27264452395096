import {POST, GET} from "@/services/gateway";

const updateSettings = ({dailyNotificationsEnabled}, providerTenantType) => {
  return POST('account/update-settings', {providerTenantType, dailyNotificationsEnabled})
}

const sendEmail = (providerTenantType) => {
  return GET(`superadmin/email/updates/generate-daily-report?providerTenantType=${providerTenantType}`)
}

export {
  updateSettings,
  sendEmail,
}
