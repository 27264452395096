import {updateAutoAdd, list} from "@/services/api/app/seat-management/backupsettings";
import {getEnableSpecialFolders} from "@/services/api/app/common/enableBackup";

const defaultState = () => ({
  autoAdd: {
    users: true,
    sites: true,
    teams: true,
    mailboxes: true,
    areEwsSpecialFoldersEnabled: true,
  },
});

export default {
  namespaced: true,

  actions: {
    list({commit}) {
      return list()
        .then((data) => {
          if (data?.autoAdd) {
            commit('setAutoAdd', {
              ...data,
              ...data.autoAdd,
            })
          }
        })
    },
    updateAutoAdd({commit}, data) {
      return updateAutoAdd(data)
        .then(({autoAdd, ...data}) => {
          commit('setAutoAdd', {...autoAdd, ...data})
        })
    },
    getEnableSpecialFolders({commit, dispatch, state}) {
      return getEnableSpecialFolders()
        .then((data) => {
          state.autoAdd = data
        })
    },

  },

  mutations: {
    setAutoAdd(state, autoAdd) {
      state.autoAdd = autoAdd
    }
  },


  getters: {
    //seatType: usersSeat, sitesSeat, teamsSeat, mailboxSeat
    getAutoAddSettingByType: (state) => (seatType) => {
      return state.autoAdd && state.autoAdd[seatType];
    },
  },

  state: defaultState()
}
