import recovery from "@/store/app/box/recovery/recovery";
import boxUsers from '@/store/app/box/seat-management/user'
import stats from "@/store/app/box/stats";
import report from "@/store/app/box/report";
import userReport from "@/store/app/box/userReport";
import backups from "@/store/app/box/backups";
import backupSettings from "@/store/app/box/seat-management/backupsettings";
import allEventLogs from "@/store/app/box/activity-logs/allEventLogs";
import editDomain from "@/store/app/box/editDomain";
import backedUpItems from "@/store/app/box/activity-logs/backedUpItems";
import backupsByDay from "@/store/app/box/activity-logs/backupEventsByDay";
import backupItems from "@/store/app/box/activity-logs/backupItems";
import settings from "@/store/app/box/settings";

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    users: boxUsers,
    stats: stats,
    report,
    userReport,
    backups,
    backupSettings,
    allEventLogs,
    editDomain,
    backedUpItems,
    backupsByDay,
    backupItems,
    settings
  }
}
